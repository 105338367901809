
function App() {
  return (
    
    <>
  {/* Start Preloader Area */}
  {/* <div className="preloader">
    <div className="preloader-inner">
      <span />
      <span />
    </div>
  </div> */}
  {/* End Preloader Area */}
  {/* Start Navbar Area */}
  <nav
    className="main-index-nav navbar navbar-b navbar-trans fixed-top navbar-expand-lg"
    id="mainNav"
  >
    <div className="container">
      <a className="navbar-brand js-scroll" href="index.html">
        <img
          src="assets/img/logo-black.png"
          className="white-logo"
          alt="logo"
        />
        <img
          src="assets/img/logo-black.png"
          className="black-logo"
          alt="logo"
        />
      </a>
      <button
        className="navbar-toggler collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#navbarDefault"
        aria-controls="navbarDefault"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span />
        <span />
        <span />
      </button>
      <div
        className="navbar-collapse collapse justify-content-end"
        id="navbarDefault"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link js-scroll active" href="#home">
              Accueil
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll" href="#about">
              Qui sommes-nous
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll" href="#services">
              Nos Services
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link js-scroll" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  {/* End Navbar Area */}
  {/* Start Home Section */}
  <section
    id="home"
    className="home-area home-animation hero-equal-height section-padding"
  >
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12">
          <div className="text-left home-content z-index position-relative">
            <h1>Smart Data System</h1>
            <p>
            Smart Data System SARL reprend les activités de CODMS fondée en 2013.
            Dynamique née d'une véritable passion pour l’informatique décisionnelle, 
            SDS a pour objectif d’accompagner les entreprises dans la réalisation de leur projet de digitalisation.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="home-shape-animation">
      <div className="shape-1">
        <img src="assets/img/shape/1.png" alt="shape image" />
      </div>
      <div className="shape-2">
        <img src="assets/img/shape/2.png" alt="shape image" />
      </div>
      <div className="shape-3">
        <img src="assets/img/shape/3.png" alt="shape image" />
      </div>
      <div className="shape-4">
        <img src="assets/img/shape/4.png" alt="shape image" />
      </div>
      <div className="shape-5">
        <img src="assets/img/shape/5.png" alt="shape image" />
      </div>
      <div className="shape-6">
        <img src="assets/img/shape/6.png" alt="shape image" />
      </div>
      <div className="shape-7">
        <img src="assets/img/shape/3.png" alt="shape image" />
      </div>
    </div>
  </section>
  {/* End Home Section */}
  {/* Start Partner Logo Section */}
  {/* <div class="partner-area pt-70 pb-30">
  <div class="container">
      <div id="partner-carousel" class="partner-carousel owl-carousel owl-theme owl-loaded">
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-1.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-2.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-3.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-4.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-5.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-6.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-7.png" alt="partner-image">
          </div>
          <div class="partner-item">
              <img src="assets/img/partner-logo/client-8.png" alt="partner-image">
          </div>
      </div>
  </div>
    </div> */}
  {/* End Partner Logo Section */}
  {/* Start About Section */}
  <section id="about" className="about-area section-padding">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="about-image-wrapper">
            <img src="assets/img/about.png" alt="About image" />
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="about-content">
            <div className="about-content-text">
          
              <h4>
              Nous proposons les meilleures solutions informatiques créatives  &amp; accompagnons les entreprises dans leurs projets de digitalisation.
              </h4>
             
              <p>
                <br/>
              <b>APPROCHE CONSULTATIVE</b>
              <p> - Franchir la ligne d'arrivée à vos côtés</p>
           
              <b>MÉTHODOLOGIE AGILE</b>
              <p> - Notre agilité au cœur de votre transformation numérique</p>
              <b>PROJET ÉVOLUTIF</b>
              <p> - Evoluer au même rythme que votre entreprise</p>
              <b>SUIVI RIGOUREUX</b>
              <p> - Le résultat est propre à chaque contexte</p>
              </p>
              {/* <a class="button" href="#">Discover More</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End About Section */}
  {/* Start Service Section */}
  <section id="services" className="services-area bg-gray section-padding">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-title">
            <h2>Nos Services</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-services-item">
            <div className="services-icon">
              <i className="icon-lightbulb" />
            </div>
            <div className="services-info">
              <h6>Solutions créatives</h6>
              <p>
              Brainstorming et cartographie des solutions sur le marché,
               étude collaborative, approfondie et prototypage 
               sont les clés pour construire et produire des solutions créatives.
      
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-services-item">
            <div className="services-icon">
              <i className="icon-layers" />
            </div>
            <div className="services-info">
              <h6>Développement de logiciels</h6>
              <p>
              Fondé sur une méthodologie de gestion de projet agile, une collaboration étroite avec 
              les parties prenantes et le respect des meilleures pratiques 
              en matière de sécurité et de qualité du code.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-services-item">
            <div className="services-icon">
              <i className="icon-mobile" />
            </div>
            <div className="services-info">
              <h6>Conception adaptative</h6>
              <p>
              Un design responsive offrant une meilleure expérience client et un visuel optimal aux utilisateurs de nos différentes solutions,
              WEB,MOBILE,IOT,BI et BIG DATA.
              <br/>
              <br/>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-services-item">
            <div className="services-icon">
              <i className="icon-gears" />
            </div>
            <div className="services-info">
              <h6>Développement web</h6>
              <p>
              Essentiellement basé sur des solutions éprouvées et robustes, nous entamons un processus
               de prototypage pour concrétiser nos concepts et itérer rapidement vers des solutions innovantes et fonctionnelles.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-services-item">
            <div className="services-icon">
              <i className="icon-briefcase" />
            </div>
            <div className="services-info">
              <h6>Solution et Développement BI </h6>
              <p>
              Nous proposons des solutions from scratch ou d’éditeurs bien établis, 
              tels que Microsoft, Qlik, Tableau, et bien d'autres encore, 
              en fonction des besoins spécifiques de nos clients et des exigences du projet.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="single-services-item">
            <div className="services-icon">
              <i className="icon-puzzle" />
            </div>
            <div className="services-info">
              <h6>Optimisation d'application</h6>
              <p>
              Nos solutions se concentrent sur l'optimisation des applications grâce à une utilisation intelligente et efficace des données.
               Nous travaillons en étroite collaboration avec nos clients.
          
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* End Service Section */}
  {/* Start Contact Section */}
  <section id="contact" className="contact-area section-padding">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="section-title">
            <h2>Contactez-nous</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-5 col-md-12">
          <div className="row contact-information">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="contact-info-title">
                <h3>Infos</h3>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="contact-details">
                <h6>Email:</h6>
                <p>info@smart-data-system.com</p>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="contact-details">
                <h6>Adresse:</h6>
                <p>Abidjan,Bingerville Abatta nouveau goudron</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-12">
          <p className="form-message" />
          <br />
          <form className="contact-form form" id="contact-form">
            <div className="controls">
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group has-error has-danger">
                    <input
                      id="form_name"
                      type="text"
                      name="name"
                      placeholder="Votre nom"
                      required="required"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group has-error has-danger">
                    <input
                      id="form_email"
                      type="email"
                      name="email"
                      placeholder="Votre email"
                      required="required"
                    />
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group has-error has-danger">
                    <input
                      id="form_subject"
                      type="text"
                      name="subject"
                      placeholder="Objet"
                      required="required"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      id="form_message"
                      name="message"
                      placeholder="Message"
                      rows={4}
                      required="required"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="button">
                    Envoyez
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  {/* End Contact Section */}
  {/* Start Footer Section */}
  <footer className="footer-area">
    <div className="footer-top-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 footer-box-item">
            <div className="footer-content">
              <img src="assets/img/logo.png" alt="Brand" />
              <p>
              Smart Data System SARL reprend les activités de CODMS fondée en 2013. 
              </p>
              <ul className="footer-social">
                <li>
                  <a href="#">
                    <i className="fa fa-facebook" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-instagram" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa fa-youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 footer-box-item">
            <div className="footer-content mid-content"></div>
          </div>
          <div className="col-lg-4 col-md-6 footer-box-item">
            <div className="footer-content">
              <h3>Contactez-nous</h3>
              <ul className="footer-link">
                <li>
                  <i className="fa fa-envelope-o" aria-hidden="true" />{" "}
                  <a href="mailto:demo@example.com">info@smart-data-system.com</a>
                </li>
                <li>
                  <i className="fa fa-home" aria-hidden="true" /> Abidjan,Bingerville Abatta nouveau goudron
                </li>
              </ul>
              <div className="subscribe-form-wrap">
                <form action="#" className="subscribe-form">
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder="Enter Email"
                  />
                  <button type="submit" className="submit-btn">
                    S'abonner
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  {/* End Footer Section */}
  {/* Start Back to Top */}
  <div className="back-to-top">
    <i className="fa fa-caret-up" />
    <i className="fa fa-caret-up" />
  </div>
  {/* End Back to Top */}
  {/* Site All Jquery Js */}
  {/*Site Main Custom Js*/}
  
</>


  );
}

export default App;
